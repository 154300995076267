.table-buttons-action {
  padding: 0rem 0rem !important;
}

div .table-responsive {
  min-height: 350px !important;
}

.button-filter {
  padding-top: 30px
}

.add-min-space {
  min-width: 130px !important;
}

.need_review {
  background-color: #ffc8bb;
}

#nprogress {
  pointer-events: auto !important;
}

.button_menu_title_tables {
  float: right;
}

.username_title_table {
  padding-left: 30%;
}

.select_in_input {
  background-color: #e4e7ea
}

.img-barcode {
  max-width: 350px;
  float: right;
}

.rdt_Table{
  min-height: 440px !important;
}

.oxxo_logo {
  height: 70px;
  width: 100px;
}

.dropdown-container {
  width: 300px;
}

.bg-PENDIENTE {
  color: #000000;
  background-color: #fed1aa
}

.bg-PROCESO {
  color: #000000;
  background-color: #a7cbfd
}

.bg-PAUSADO {
  color: #000000;
  background-color: #f3b6bd
}

.bg-VISITA {
  color: #000000;
  background-color: #acdee5
}

.bg-FINALIZADO {
  color: #000000;
  background-color: #b2e0bc
}

.bg-ARCHIVADO {
  color: #000000;
  background-color: #f7f8fa
}

.badge-PENDIENTE {
  color: #000000;
  background-color: #fed1aa
}

.badge-PROCESO {
  color: #000000;
  background-color: #a7cbfd
}

.badge-PAUSADO {
  color: #000000;
  background-color: #f3b6bd
}

.badge-VISITA {
  color: #000000;
  background-color: #acdee5
}

.badge-FINALIZADO {
  color: #000000;
  background-color: #b2e0bc
}

.badge-ARCHIVADO {
  color: #000000;
  background-color: #f7f8fa
}

.calendar-todo-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-ul {
  padding-left: 2px !important;
}

.calendar-todo-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-textfield-wrapper {
  display: inline-block;
  margin: 3px;
  z-index: 3;
  line-height: normal;
}

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-textfield {
  width: 140px;
  height: 32px;
}

.md-recurring-event-editor-popup .md-recurrence-input-nr .mbsc-textfield {
  width: 90px;
}

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-select-icon {
  top: 50%;
  height: 1.5em;
  margin-top: -0.75em;
}

.md-recurrence-options {
  padding: 0 1em;
  margin: 1em 0;
}

.md-recurrence-ends {
  padding: 0 1em;
  margin: 1.25em 0 -1.25em 0;
}

.md-recurrence-desc {
  font-size: 12px;
  font-weight: normal;
  color: #555;
  margin: 1em 0;
}

.md-hide-elm {
  display: none;
}

.event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}

.event-color-label {
  flex: 1 0 auto;
}

.event-color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 240px;
  background: #5ac8fa;
}

.crud-color-row {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.crud-color-c {
  padding: 3px;
  margin: 2px;
}

.crud-color {
  position: relative;
  min-width: 46px;
  min-height: 46px;
  margin: 2px;
  cursor: pointer;
  border-radius: 23px;
  background: #5ac8fa;
}

.crud-color-c.selected,
.crud-color-c:hover {
  box-shadow: inset 0 0 0 3px #007bff;
  border-radius: 48px;
}

.crud-color:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  color: #f7f7f7;
  font-size: 20px;
  text-shadow: 0 0 3px #000;
  display: none;
}

.crud-color-c.selected .crud-color:before {
  display: block;
}

.md-search-events-cont {
  width: 350px;
}

.md-search-events-cont .mbsc-textfield-wrapper.mbsc-ios {
  margin-top: 8px;
  margin-bottom: 9px;
}

.md-search-events-cont .mbsc-textfield-wrapper.mbsc-material {
  margin-top: 15px;
  margin-bottom: 15px;
}

.md-search-events-cont .mbsc-textfield-wrapper.mbsc-windows {
  margin-top: 28px;
  margin-bottom: 29px;
}

@media (min-width: 1135px) {
  .md-search-events-cont .mbsc-textfield-wrapper.mbsc-ios {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.md-search-events-calendar {
  border-left: 1px solid #ccc;
}

.demo-searching-events-in-sidebar,
.md-search-events-sidebar,
.md-search-events-calendar {
  height: 100%;
}

.md-custom-header-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
}

.md-custom-header-nav,
.md-custom-header-view {
  width: 180px;
}

.md-custom-header-button.mbsc-button {
  font-size: 20px;
  height: auto;
  padding: 0;
  margin: 0;
}

.md-custom-header .mbsc-segmented {
  width: 110px;
  float: right;
  margin-top: 0;
  margin-bottom: 0;
}

.md-custom-header .mbsc-material.mbsc-segmented,
.md-custom-header .mbsc-windows.mbsc-segmented {
  padding: 0;
}

.md-custom-header .mbsc-segmented .mbsc-segmented-button {
  font-size: 20px;
  height: 32px;
  padding: 0;
}

.md-custom-header-filtering .mbsc-segmented {
  max-width: 400px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.md-header-filter-img {
  width: 25px;
}

.md-header-filter-name {
  margin-left: 10px;
}

.md-header-filter-nav {
  width: 200px;
}

.md-header-filter-controls .mbsc-segmented-button.mbsc-selected {
  color: #fff;
}

.md-custom-header-filtering .mbsc-segmented-item:first-child .mbsc-selected.mbsc-material,
.md-custom-header-filtering .mbsc-segmented-item:first-child .mbsc-selected.mbsc-windows,
.md-custom-header-filtering .mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
  background: #328e39;
}

.md-custom-header-filtering .mbsc-segmented-item:nth-child(2) .mbsc-selected.mbsc-material,
.md-custom-header-filtering .mbsc-segmented-item:nth-child(2) .mbsc-selected.mbsc-windows,
.md-custom-header-filtering .mbsc-segmented-item:nth-child(2) .mbsc-segmented-selectbox-inner {
  background: #00aabb;
}

.md-custom-header-filtering .mbsc-segmented-item:nth-child(3) .mbsc-selected.mbsc-material,
.md-custom-header-filtering .mbsc-segmented-item:nth-child(3) .mbsc-selected.mbsc-windows,
.md-custom-header-filtering .mbsc-segmented-item:nth-child(3) .mbsc-segmented-selectbox-inner {
  background: #ea72c0;
}

.md-seach-header-bar .mbsc-textfield-wrapper.mbsc-form-control-wrapper {
  width: 400px;
  margin: 12px auto;
}

.md-search-popup .mbsc-popover-list {
  width: 400px;
}

.md-search-popup .mbsc-event-list {
  margin-top: -1px;
  margin-bottom: -1px;
}

.md-search-events .mbsc-ios-dark.mbsc-textfield-box {
  background: #313131;
}

.md-sync-events-google-cont.mbsc-page {
  display: flex;
  height: 600px;
}

.md-sync-events-google-menu {
  flex: 0 0 350px;
  /*height: 100%;*/
  overflow: auto;
}

.md-sync-events-google-calendar {
  flex: 1 1 auto;
  border-left: 1px solid #ccc;
}

.md-google-calendar-header {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
}

button.md-sync-events-google-button {
  padding: 8px 24px 8px 50px;
  line-height: 24px;
  background: url(https://cdn.cdnlogo.com/logos/g/35/google-icon.svg) no-repeat;
  background-size: 18px 18px;
  background-position: 11px 11px;
  background-color: #fff;
  color: #757575;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.md-sync-events-google-button:active {
  background-color: #eee;
  color: #6d6d6d;
}

.md-sync-events-google-button:hover,
.md-sync-events-google-button:focus {
  box-shadow: 0 0 3px 3px rgb(66, 133, 244, .3);
}

.md-sync-events-google-inset {
  margin-bottom: 0;
}

@media (max-width: 800px) {
  .md-sync-events-google-cont.mbsc-page {
    display: block;
    height: auto;
  }
}

/* loading spinner and overlay */

.md-loading-events .md-sync-events-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.md-spinner {
  visibility: hidden;
  position: relative;
  width: 20px;
  height: 20px;
}

.md-loading-events .md-spinner {
  visibility: visible;
}

.md-spinner .md-spinner-blade {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 25%;
  border-radius: 50%/20%;
  background-color: #8C8C8C;
  -webkit-animation: md-spinner-fade 1s linear infinite;
  animation: md-spinner-fade 1s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.md-spinner .md-spinner-blade:nth-child(1) {
  -webkit-animation-delay: -1.66667s;
  animation-delay: -1.66667s;
  -webkit-transform: rotate(30deg) translate(0, -150%);
  transform: rotate(30deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(2) {
  -webkit-animation-delay: -1.58333s;
  animation-delay: -1.58333s;
  -webkit-transform: rotate(60deg) translate(0, -150%);
  transform: rotate(60deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(3) {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
  -webkit-transform: rotate(90deg) translate(0, -150%);
  transform: rotate(90deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(4) {
  -webkit-animation-delay: -1.41667s;
  animation-delay: -1.41667s;
  -webkit-transform: rotate(120deg) translate(0, -150%);
  transform: rotate(120deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(5) {
  -webkit-animation-delay: -1.33333s;
  animation-delay: -1.33333s;
  -webkit-transform: rotate(150deg) translate(0, -150%);
  transform: rotate(150deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(6) {
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
  -webkit-transform: rotate(180deg) translate(0, -150%);
  transform: rotate(180deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(7) {
  -webkit-animation-delay: -1.16667s;
  animation-delay: -1.16667s;
  -webkit-transform: rotate(210deg) translate(0, -150%);
  transform: rotate(210deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(8) {
  -webkit-animation-delay: -1.08333s;
  animation-delay: -1.08333s;
  -webkit-transform: rotate(240deg) translate(0, -150%);
  transform: rotate(240deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(9) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: rotate(270deg) translate(0, -150%);
  transform: rotate(270deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(10) {
  -webkit-animation-delay: -0.91667s;
  animation-delay: -0.91667s;
  -webkit-transform: rotate(300deg) translate(0, -150%);
  transform: rotate(300deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(11) {
  -webkit-animation-delay: -0.83333s;
  animation-delay: -0.83333s;
  -webkit-transform: rotate(330deg) translate(0, -150%);
  transform: rotate(330deg) translate(0, -150%);
}

.md-spinner .md-spinner-blade:nth-child(12) {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
  -webkit-transform: rotate(360deg) translate(0, -150%);
  transform: rotate(360deg) translate(0, -150%);
}

.md-loading-events .md-spinner-blade {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes md-spinner-fade {
  0% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes md-spinner-fade {
  0% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}

.external-drop-calendar {
  border-right: 1px solid #ccc;
  z-index: 0;
}

.external-drop-task {
  color: #fff;
  padding: 10px;
  margin: 20px;
  border-radius: 8px;
  font-family: -apple-system, Segoe UI, Roboto, sans-serif;
}

.external-drop-task-blank {
  color: #999;
  border-width: 2px;
  border-style: dotted;
}

.demo-external-drag-drop.demo-wrapper,
.demo-external-drag-drop .mbsc-grid,
.demo-external-drag-drop .mbsc-row,
.demo-external-drag-drop .external-drop-calendar {
  height: 100%;
}

.mbsc-material.mbsc-calendar-controls {
  padding: .0em !important;
}

.multi-day-event {
  padding-left: 5px;
}

.switch {
  padding-top: 8px;
}

.logo_image {
  height: 40px;
  width: 40px;
}
